.spinner svg {
  animation: rotate 1.5s linear infinite;
  height: 100%;
  width: 100%;
}

.spinner circle {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  animation: 
    dash 1.5s ease-in-out infinite 0s;
  stroke-linecap: round;
  fill: none;
  stroke-width:3;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}