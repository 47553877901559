@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "DM Sans", sans-serif;
}

body {
  background: #020617;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.selected-image {
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.5));
}
